import { UserForFilter } from './../../../models/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
// import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { CustomerStatsOptions } from 'src/app/enums/customer-stats-options.enum';
import { AccountKycList } from 'src/app/models/accountKyc.model';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import {
  Customer,
  CustomerStats,
  SortByOptions,
  FilterByOptions,
  AssetStatus,
  ServiceType,
  BuildingType,
  UserType,
  NewEntryOptions,
} from 'src/app/models/customer.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum, SystemComponents } from 'src/app/models/role.model';
import { Tariff } from 'src/app/models/tariff.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { PermissionsService } from '../../../services/permissions.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
@Component({
  selector: 'customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, OnDestroy {
  customers: Customer[] = [];
  stats: CustomerStats = new CustomerStats();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');

  mapSortBy: Record<number, any> = {
    [SortByOptions.NEWEST]: {
      label: 'Newest',
      translation: 'COMMON.SORT_BY.NEWEST',
      arrow: 'no',
    },
    [SortByOptions.SLRN_ASC]: {
      label: 'SLRN',
      translation: 'COMMON.SLRN',
      arrow: 'up',
    },
    [SortByOptions.SLRN_DESC]: {
      label: 'SLRN',
      translation: 'COMMON.SLRN',
      arrow: 'down',
    },
    [SortByOptions.ACCNO_ASC]: {
      label: 'AccNo.',
      translation: 'COMMON.SORT_BY.ACCOUNT_NO',
      arrow: 'up',
    },
    [SortByOptions.ACCNO_DESC]: {
      label: 'AccNo.',
      translation: 'COMMON.SORT_BY.ACCOUNT_NO',
      arrow: 'down',
    },
    [SortByOptions.OLDEST]: {
      label: 'Oldest',
      translation: 'COMMON.SORT_BY.OLDEST',
      arrow: '',
    },
  };
  dateFrom: string;
  dateTo: string;

  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
  filterByOptions = FilterByOptions;
  filterByValue?: FilterByOptions;
  newEntryOptions = NewEntryOptions;
  newEntryValue?: NewEntryOptions;
  assetStatusOptions = AssetStatus;
  public AssetStatus = AssetStatus;
  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;

  showUpload: boolean = false;
  showSortBy: boolean = false;
  showDirectConnection: boolean = false;
  historyItems: any[] = [];
  isViewHistory: boolean = true;

  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();

  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  tariff: number = 0;
  tariffs: Tariff[] = [];
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  serviceType?: number;
  showDownload = false;
  downloadCustomer = false;
  downloadMissingSlrn = false;
  selectedStatuses: number[] = [];
  userType?: number;
  userTypeOptions = [
    {
      name: 'First Captured User',
      value: UserType.FirstCapturedUser,
      translation: 'COMMON.USER.TYPE.FIRST_CAPTURED',
    },
    {
      name: 'Last Captured User',
      value: UserType.LastCapturedUser,
      translation: 'COMMON.USER.TYPE.LAST_CAPTURED',
    },
    {
      name: 'Reassigned By',
      value: UserType.ReassignedBy,
      translation: 'COMMON.USER.TYPE.REASSIGNED',
    },
    {
      name: 'Validated By',
      value: UserType.ValidatedBy,
      translation: 'COMMON.USER.TYPE.VALIDATED',
    },
    {
      name: 'Audited By',
      value: UserType.AuditedBy,
      translation: 'COMMON.USER.TYPE.AUDITED',
    },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];

  auditStatus?: number;
  statusOptions = [
    {
      id: 0,
      name: 'Pending',
      value: AssetStatus.Pending,
      translation: 'COMMON.ASSET_STATUS.PENDING',
      checked: false,
    },
    {
      id: 1,
      name: 'Complete',
      value: AssetStatus.Complete,
      translation: 'COMMON.ASSET_STATUS.COMPLETE',
      checked: false,
    },
    {
      id: 4,
      name: 'Rejected',
      value: AssetStatus.Rejected,
      translation: 'COMMON.ASSET_STATUS.REJECTED',
      checked: false,
    },
    {
      id: 3,
      name: 'Assigned',
      value: AssetStatus.Assigned,
      translation: 'COMMON.ASSET_STATUS.ASSIGNED',
      checked: false,
    },
  ];
  auditStatusOptions = [
    {
      name: 'Audited',
      value: 1,
      translation: 'COMMON.ASSET_STATUS.AUDITED',
    },
    {
      name: 'Non Audited',
      value: 0,
      translation: 'COMMON.ASSET_STATUS.NON_AUDITED',
    },
  ];

  tag?: number;
  tagOptions = [
    { name: 'Existing', value: 0, translation: 'COMMON.TAG.EXISTING' },
    { name: 'New entry', value: 1, translation: 'COMMON.TAG.NEW_ENTRY' },
  ];
  kycTag?: number;
  kycTagOptions = [
    { name: 'Existing', value: 0 },
    { name: 'Onboard', value: 1 },
  ];
  kycStatus?: number;
  kycStatusOptions = [
    { name: 'Pending', value: 0 },
    { name: 'Rejected', value: 1 },
    { name: 'Approved', value: 2 },
  ];
  sltStatus?: number;

  useOfPremises?: number;
  uopOptions = [
    {
      name: 'Residential',
      translation: 'COMMON.BUILDING_TYPE.RESIDENTIAL',
      value: BuildingType.Residential,
    },
    {
      name: 'Commercial',
      translation: 'COMMON.BUILDING_TYPE.COMMERCIAL',
      value: BuildingType.Commercial,
    },
    {
      name: 'Industrial',
      translation: 'COMMON.BUILDING_TYPE.INDUSTRIAL',
      value: BuildingType.Industrial,
    },
    {
      name: 'Mixed',
      translation: 'COMMON.BUILDING_TYPE.MIXED',
      value: BuildingType.Mixed,
    },
    {
      name: 'Religious House',
      translation: 'COMMON.BUILDING_TYPE.RELIGIOUSHOUSE',
      value: BuildingType.ReligiousHouse,
    },
  ];

  clicked: boolean = false;

  selectedFilters: ChecklistItem[] = [];
  utilityId: number = 0;
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    {
      name: 'LastUpdateTime',
      value: 'Last Update Time',
      translation: 'COMMON.DATE_TYPE.LAST_UPDATE',
    },
    {
      name: 'TaggedDate',
      value: 'Tagged Date',
      translation: 'COMMON.DATE_TYPE.TAGGED',
    },
    {
      name: 'ValidatedDate',
      value: 'Validated Date',
      translation: 'COMMON.DATE_TYPE.VALIDATED',
    },
  ];
  searchByOptions = [
    {
      name: 'AccountNumber',
      value: 'Account No.',
      translation: 'CUSTOMERS.SEARCH_BY.ACCOUNT_NO',
    },
    { name: 'Slrn', value: 'SLRN', translation: 'CUSTOMERS.SEARCH_BY.SLRN' },
    {
      name: 'MeterNumber',
      value: 'Meter No.',
      translation: 'CUSTOMERS.SEARCH_BY.METER_NO',
    },
    {
      name: 'BuildingOwner',
      value: 'Building Owner',
      translation: 'CUSTOMERS.SEARCH_BY.BUILDING_OWNER',
    },
    {
      name: 'PhoneNumber',
      value: 'Phone No.',
      translation: 'CUSTOMERS.SEARCH_BY.PHONE_NO',
    },
    { name: 'Email', value: 'Email', translation: 'CUSTOMERS.SEARCH_BY.EMAIL' },
    {
      name: 'ContractNumber',
      value: 'Contract No.',
      translation: 'COMMON.CONTRACT_NUMBER',
    },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  selectedSearchType: string;
  selectedSearchTypeDisplay: string;
  showSelectSearchType: boolean = false;
  searchFilterApplied: boolean = false;
  regularizedInfoOpen: boolean = false;
  totalInfoOpen: boolean = false;
  newInfoOpen: boolean = false;
  sltOptions: OptionObj[] = [];
  isServiceTypeFieldVisible: boolean = true;
  isRegularizationUploadVisible: boolean = true;
  navigationExtras?: NavigationExtras;
  currency: string = '';

  dtId?: number;
  dtName?: string;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private areaService: AreaService,
    private tariffService: TariffService,
    private translationService: TranslationService,
    private userService: UserService, // private analytics: AngularFireAnalytics
    public permissionsService: PermissionsService,
    public analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.navigationExtras = this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    if (this.navigationExtras?.state) {
      this.setDtFilter();
    }
    this.setFieldsVisibility();
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.currency = localStorage.getItem('currency') || '';
    this.subscribeToClickEvent();
    this.setFilters();
    this.getRegions();
    this.getTariffs();
    this.getSltStatuses();
    this.getCustomerStats();
    this.setCheckedUsers();
    this.getData();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((_) => {
      if (this.searchText == '') this.search();
      this.searchFilterApplied = false;
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;

    this.selectedSearchType =
      this.selectedSearchType ?? this.searchByOptions[0].name;
    this.selectedSearchTypeDisplay =
      this.selectedSearchTypeDisplay ?? this.searchByOptions[0].translation;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.searchTextUpdate.complete();
  }

  setDtFilter() {
    if (!localStorage.getItem('filterCustomer')) {
      this.dtId = this.navigationExtras!.state!['dtId'];
      this.dtName = this.navigationExtras!.state!['dtName'];
      this.setFilterStorage();
    }
  }

  private getData(): void {
    this.reloadTable();
  }

  getTotalSubCardsStats() {
    if (this.totalInfoOpen) {
      var obj = this.getFilterObject();
      this.getActiveAccountStats(obj);
      this.getInactiveAccountStats(obj);
    }
  }
  private getActiveAccountStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.ActiveAccountStatus,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.activeAccountStatus = data.data.activeAccountStatus;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getInactiveAccountStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.InactiveAccountStatus,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.inactiveAccountStatus = data.data.inactiveAccountStatus;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getNewEntrySubCardsStats() {
    if (this.newInfoOpen) {
      var obj = this.getFilterObject();
      this.getWithAccNoStats(obj);
      this.getMeterNumberStartsWithStats(obj);
      this.getAlphaMeterTypeStats(obj);
      this.getInvalidMeterNumberStats(obj);
      this.getWithSpnStats(obj);
    }
  }
  private getWithAccNoStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.WithAccNo,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.withAccNo = data.data.withAccNo;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getMeterNumberStartsWithStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.MeterNumberStartsWith,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.meterNumberStartsWith = data.data.meterNumberStartsWith;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAlphaMeterTypeStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.AlphaMeterType,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.alphaMeterType = data.data.alphaMeterType;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getInvalidMeterNumberStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.InvalidMeterNumber,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.invalidMeterNumber = data.data.invalidMeterNumber;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getWithSpnStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.WithSpn,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.withSpn = data.data.withSpn;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getRegularizedSubCardsStats() {
    if (this.regularizedInfoOpen) {
      var obj = this.getFilterObject();
      this.getTotalRevenueStats(obj);
      this.getRegilarizedPayingCustomersStats(obj);
    }
  }
  private getTotalRevenueStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.RegularizedTotalRevenue,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.regularizedTotalRevenue =
            data.data.regularizedTotalRevenue;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getRegilarizedPayingCustomersStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.RegularizedConsidered,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.regularizedConsidered = data.data.regularizedConsidered;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getCustomerStats() {
    var obj = this.getFilterObject();
    this.stats = new CustomerStats();
    this.getExistingStats(obj);
    if (this.permissionsService.isCustomerNewEntryBreakdownAllowed) {
      this.getNewStats(obj);
    }
    if (this.permissionsService.isCustomerRegularizationAllowed)
      this.getRegularizedStats(obj);
    this.getDirectStats(obj);
    this.getRetaggedStats(obj);
    this.getUnvalidatedStats(obj);
  }
  private getExistingStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Existing,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.existing = data.data.existing;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getNewStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.New,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        console.log(data);

        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.new = data.data.new;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getRegularizedStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Regularized,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.regularized = data.data.regularized;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getDirectStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Direct,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.onboard = data.data.onboard;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getRetaggedStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Retagged,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.retagged = data.data.retagged;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getUnvalidatedStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Unvalidated,
    };
    this.customerService.getCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats.unvalidated = data.data.unvalidated;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  setFilters() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedSearchType = filterObject.filter.filterParams.searchType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.translation ?? this.dateTypes[0].translation;
    this.selectedSearchTypeDisplay =
      this.searchByOptions.find(
        (type) => type.name == filterObject.filter.filterParams.searchType
      )?.translation ?? this.searchByOptions[0].translation;
    this.tariff = filterObject.filter.filterParams.tariffId
      ? filterObject.filter.filterParams.tariffId
      : 0;
    // this.serviceType = filterObject.filter.filterParams.serviceType
    //   ? filterObject.filter.filterParams.serviceType
    //   : undefined;
    this.userType = filterObject.filter.filterParams.userType
      ? filterObject.filter.filterParams.userType
      : undefined;
    this.auditStatus = filterObject.filter.filterParams.auditStatus;
    this.tag =
      filterObject.filter.filterParams.tag != null
        ? filterObject.filter.filterParams.tag
          ? 1
          : 0
        : undefined;
    this.sltStatus = filterObject.filter.filterParams.sltStatus
      ? filterObject.filter.filterParams.sltStatus
      : undefined;
    this.useOfPremises = filterObject.filter.filterParams.useOfPremises
      ? filterObject.filter.filterParams.useOfPremises
      : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
    this.dtId = filterObject.filter.filterParams.dtId
      ? filterObject.filter.filterParams.dtId
      : 0;
    this.dtName = filterObject.filter.filterParams.dtName
      ? filterObject.filter.filterParams.dtName
      : undefined;
    if (filterObject.filter.filterParams.selectedStatuses) {
      this.selectedStatuses = filterObject.filter.filterParams.selectedStatuses;
      this.statusOptions = this.statusOptions.map((status) => ({
        ...status,
        checked: this.selectedStatuses.includes(status.id),
      }));
    } else this.selectedStatuses = [];
    this.selectedStatuses = filterObject.filter.filterParams.selectedStatuses
      ? filterObject.filter.filterParams.selectedStatuses
      : [];
    this.sortByLabelValue = this.mapSortBy[this.sortByValue];
    this.newEntryValue = filterObject.filter.filterParams.newEntry;
    this.showDirectConnection =
      filterObject.filter.filterParams.showDirectConnection;
  }

  setCheckedAreas() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  async setCheckedUsers() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (
      //   filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }

  setCheckedRegions() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };
    this.analyticsService.logCustomEvent('CUSTOMERS: Filter list', {
      page: obj.pageInfo.page,
      sortBy: obj.filterParams.sortBy,
      search: obj.filterParams.search,
      filterCard: obj.filterParams.filterBy,
      userType: obj.filterParams.userType,
      dateFrom: obj.filterParams.dateFrom,
      dateTo: obj.filterParams.dateTo,
      users: obj.filterParams.selectedUsers,
      dateType: obj.filterParams.dateType,
      selectedStatuses: obj.filterParams.selectedStatuses,
      tag: obj.filterParams.tag,
      areas: obj.filterParams.selectedAreas,
      regions: obj.filterParams.selectedRegions,
      tariff: obj.filterParams.tariffId,
      slt: obj.filterParams.sltStatus,
      useOfPremises: obj.filterParams.useOfPremises,
      dtId: obj.filterParams.dtId,
      showDirectConnection: this.showDirectConnection,
    });

    this.getAll(obj);
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObjectForStorage(),
      utilityId: this.utilityId,
    };

    localStorage.setItem('cameFrom', 'Customer');
    localStorage.setItem(
      'filterCustomer',
      JSON.stringify({ type: 'Customer', filter: obj })
    );
  }

  private getAll(obj: any) {
    this.customerService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customers = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  checkCustomer(id: number) {
    const customer = this.customers.find((x) => x.id === id);
    if (customer) {
      customer.isChecked = !customer.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.customers.every((x) => x.isChecked);
  }

  checkAllCheckboxes() {
    this.customers.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByLabelValue = this.mapSortBy[option];
    // this.reloadTable();
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.createFilterCards();

    // this.getCustomerStats();
    // this.reloadTable(1);
  }

  onFilterBy(option: FilterByOptions) {
    if (this.filterByValue == option) {
      this.filterByValue = undefined;
    } else {
      this.filterByValue = option;
    }
    this.regularizedInfoOpen = false;
    if (option == FilterByOptions.ONBOARD) this.showDirectConnection = true;
    // this.totalInfoOpen = false;
    this.createFilterCards();
    this.reloadTable(1);
  }

  onFilterByNewEntry(option: NewEntryOptions) {
    if (this.newEntryValue == option) {
      this.filterByValue = undefined;
    } else {
      this.newEntryValue = option;
    }
    this.regularizedInfoOpen = false;
    // this.totalInfoOpen = false;
    this.filterByValue = undefined;
    this.createFilterCards();
    this.reloadTable(1);
  }

  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      searchType: this.selectedSearchType,
      tariffId: this.tariff != 0 ? this.tariff : null,
      serviceType: this.serviceType ? this.serviceType : null,
      userType: this.userType
        ? this.selectedUsers.length > 0
          ? this.userType
          : null
        : null,
      auditStatus: this.auditStatus
        ? this.auditStatus == 0
          ? false
          : true
        : null,
      tag: this.tag ? (this.tag == 0 ? false : true) : null,
      sltStatus: this.sltStatus ? this.sltStatus : null,
      useOfPremises: this.useOfPremises ? this.useOfPremises : null,
      dtId: this.dtId ? Number(this.dtId) : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      selectedIds: this.customers
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      newEntry: this.newEntryValue,
      selectedStatuses:
        this.selectedStatuses.length > 0 ? this.selectedStatuses : null,
      showDirectConnection: this.showDirectConnection,
    };

    return obj;
  }

  getFilterObjectForStorage() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? this.dateTo : null,
      dateType: this.selectedDateType,
      searchType: this.selectedSearchType,
      tariffId: this.tariff != 0 ? this.tariff : null,
      serviceType: this.serviceType ? this.serviceType : null,
      userType: this.userType ? this.userType : null,
      auditStatus: this.auditStatus
        ? this.auditStatus == 0
          ? false
          : true
        : null,
      tag: this.tag ? (this.tag == 0 ? false : true) : null,
      sltStatus: this.sltStatus ? this.sltStatus : null,
      useOfPremises: this.useOfPremises ? this.useOfPremises : null,
      dtId: this.dtId ? this.dtId : null,
      dtName: this.dtName ? this.dtName : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedStatuses:
        this.selectedStatuses.length > 0 ? this.selectedStatuses : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedIds: this.customers
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      kycStatus: this.kycStatus ? this.kycStatus : null,
      kycTag: this.kycTag ? this.kycTag : null,
      newEntry: this.newEntryValue,
      showDirectConnection: this.showDirectConnection,
    };
    return obj;
  }

  viewHistory(id: number) {
    this.customerService.getHistory(id).subscribe({
      next: (response) => {
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  downloadReplacedSlrn() {
    var obj = this.getFilterObject();
    this.analyticsService.logCustomEvent('CUSTOMERS: Download replaced SLRN', {
      sortBy: obj.sortBy,
      search: obj.search,
      filterCard: obj.filterBy,
      userType: obj.userType,
      dateFrom: obj.dateFrom,
      dateTo: obj.dateTo,
      users: obj.selectedUsers,
      dateType: obj.dateType,
      selectedStatuses: obj.selectedStatuses,
      tag: obj.tag,
      dtId: obj.dtId,
      areas: obj.selectedAreas,
      regions: obj.selectedRegions,
      tariff: obj.tariffId,
      slt: obj.sltStatus,
      useOfPremises: obj.useOfPremises,
      newEntry: obj.newEntry,
      showDirectConnection: obj.showDirectConnection,
    });

    this.customerService.downloadReplacedSlrn(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  download() {
    if (this.downloadCustomer) {
      this.downloadCustomers();
    } else if (this.downloadMissingSlrn) {
      this.downloadReplacedSlrn();
    }
  }
  downloadCustomers() {
    var obj = this.getFilterObject();
    this.analyticsService.logCustomEvent('CUSTOMERS: Download', {
      sortBy: obj.sortBy,
      search: obj.search,
      filterCard: obj.filterBy,
      userType: obj.userType,
      dateFrom: obj.dateFrom,
      dateTo: obj.dateTo,
      users: obj.selectedUsers,
      dateType: obj.dateType,
      selectedStatuses: obj.selectedStatuses,
      tag: obj.tag,
      areas: obj.selectedAreas,
      regions: obj.selectedRegions,
      tariff: obj.tariffId,
      slt: obj.sltStatus,
      useOfPremises: obj.useOfPremises,
      newEntry: obj.newEntry,
      showDirectConnection: obj.showDirectConnection,
    });

    this.customerService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
        this.showSelectSearchType = false;
      });
  }

  datesValid() {
    // this.createFilterCards();
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }

    return true;
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectTariff(e: any) {
    this.tariff = +e.target.value;
    this.createFilterCards();
    // this.getCustomerStats();
    // this.reloadTable(1);
  }

  selectServiceType(e: any) {
    if (e.target.value != '') {
      this.serviceType = e.target.value;
    } else {
      this.serviceType = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];
  }

  selectUserType(e: any) {
    if (this.userType) this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      let permission = PermissionsEnum.Customer_Assign;

      switch (Number(this.userType)) {
        case UserType.ValidatedBy:
          permission = PermissionsEnum.Customer_ApproveReject;
          break;
        case UserType.AuditedBy:
          permission = PermissionsEnum.Customer_AuditReview;
          break;
      }

      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = this.adminUsers.filter((x) =>
              x.permissions?.some((x) => Number(x) == permission)
            );
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [
          ...this.adminUsers.filter((x) =>
            x.permissions?.some((x) => x == permission)
          ),
        ];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }

  selectStatus() {
    this.createFilterCards();
  }

  selectAuditStatus(e: any) {
    if (e.target.value != '') {
      this.auditStatus = e.target.value;
    } else {
      this.auditStatus = undefined;
    }
    this.createFilterCards();
  }

  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = e.target.value;
    } else {
      this.tag = undefined;
    }
    this.createFilterCards();
    // this.getCustomerStats();
    // this.reloadTable(1);
  }
  selectKycTag(e: any) {
    if (e.target.value != '') {
      this.kycTag = e.target.value;
    } else {
      this.kycTag = undefined;
    }
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectKycStatus(e: any) {
    if (e.target.value != '') {
      this.kycStatus = e.target.value;
    } else {
      this.kycStatus = undefined;
    }
    this.createFilterCards();
    // this.reloadTable(1);
  }
  selectSltStatus(e: any) {
    if (e.target.value != '') {
      this.sltStatus = parseInt(e.target.value);
    } else {
      this.sltStatus = undefined;
    }
    this.createFilterCards();
    // this.getCustomerStats();
    // this.reloadTable(1);
  }

  selectUseOfPremises(e: any) {
    if (e.target.value != '') {
      this.useOfPremises = e.target.value;
    } else {
      this.useOfPremises = undefined;
    }
    this.createFilterCards();
    // this.getCustomerStats();
    // this.reloadTable(1);
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  applyRegionFilter() {
    // this.getCustomerStats();
    // this.reloadTable(1);
  }
  applyAreaFilter() {
    // this.getCustomerStats();
    // this.reloadTable(1);
  }
  applyUserFilter() {
    // this.getCustomerStats();
    // this.reloadTable(1);
  }

  applyFilter(item: ChecklistItem) {
    this.getCustomerStats();
    this.reloadTable(1);
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Tariff') {
      this.tariff = 0;
    } else if (item.property == 'Service type') {
      this.serviceType = undefined;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == 'Status') {
      this.statusOptions.forEach((status) => {
        if (status.name === item.selectedValue) {
          status.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Audit Status') {
      this.auditStatus = undefined;
    } else if (item.property == 'Tag') {
      this.tag = undefined;
    } else if (item.property == 'Slt status') {
      this.sltStatus = undefined;
    } else if (item.property == 'Use of premises') {
      this.useOfPremises = undefined;
    } else if (item.property == 'Type') {
      this.filterByValue = this.filterByOptions.TOTAL;
      this.showDirectConnection = false;
    } else if (item.property == 'Kyc Status') {
      this.kycStatus = undefined;
    } else if (item.property == 'Kyc Tag') {
      this.kycTag = undefined;
    } else if (item.property == 'New entry type') {
      this.newEntryValue = undefined;
    } else if (item.property == 'Date From') {
      this.dateFrom = '';
    } else if (item.property == 'Date To') {
      this.dateTo = '';
    } else if (item.property == 'Search') {
      this.searchText = '';
    } else if (item.property == 'Dt Name') {
      this.dtId = 0;
      this.dtName = '';
    }

    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.getCustomerStats();
      this.reloadTable(1);
    }
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });

    this.areas = [];

    this.statusOptions.forEach((status) => {
      status.checked = false;
    });

    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.selectedStatuses = [];
    this.tariff = 0;
    this.serviceType = undefined;
    this.userType = undefined;
    this.auditStatus = undefined;
    this.tag = undefined;
    this.sltStatus = undefined;
    this.useOfPremises = undefined;
    this.filterByValue = this.filterByOptions.TOTAL;

    this.clicked = !this.clicked;
    this.kycStatus = undefined;
    this.kycTag = undefined;
    this.newEntryValue = undefined;

    this.dtId = undefined;
    this.dtName = undefined;
    this.searchText = '';
    this.dateFrom = '';
    this.dateTo = '';
    this.showDirectConnection = false;
    this.getCustomerStats();
    this.reloadTable(1);
  }

  createFilterCards() {
    this.totalInfoOpen = false;
    this.newInfoOpen = false;
    this.regularizedInfoOpen = false;
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    let selectedUsers = this.allUsers.filter((x) => x.checked);

    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    let selectedStatuses = this.statusOptions.filter((x) => x.checked);

    this.selectedStatuses = this.statusOptions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(
          this.utName,
          selectedAreas[i].name,
          'COMMON.UT.SINGLE'
        )
      );
    }

    //statuses
    for (var i = 0; i < selectedStatuses.length; i++) {
      var status = selectedStatuses[i];
      this.selectedFilters.push(
        new ChecklistItem(
          'Status',
          status.name,
          'COMMON.STATUS',
          status.translation
        )
      );
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name, 'COMMON.USER.SINGLE')
      );
    }

    //tariff
    if (this.tariff != 0) {
      let tariffName = this.tariffs.filter((x) => x.id == this.tariff)[0]?.name;
      this.selectedFilters.push(
        new ChecklistItem('Tariff', tariffName, 'COMMON.TARIFF.SINGLE')
      );
    }

    //service type
    if (this.serviceType) {
      var serviceTypeName = ServiceType[this.serviceType!];
      this.selectedFilters.push(
        new ChecklistItem(
          'Service type',
          serviceTypeName,
          'COMMON.SERVICE_TYPE.TITLE'
        )
      );
    }

    if (this.userType) {
      var userTypeName = UserType[this.userType!];
      var userTypeTranslationKey = this.userTypeOptions.find(
        (option) => option.value == this.userType
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'User type',
          userTypeName,
          'COMMON.USER.TYPE.TITLE',
          userTypeTranslationKey
        )
      );
    }

    //audit status
    if (this.auditStatus) {
      var statusName = this.auditStatus ? 'Audited' : 'Non Audited';
      var statusTranslationKey = this.auditStatusOptions.find(
        (option) => option.value == this.auditStatus
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'Audit Status',
          statusName,
          'COMMON.AUDIT_STATUS',
          statusTranslationKey
        )
      );
    }

    //tag
    if (this.tag) {
      var tagName = this.tagOptions[this.tag].name;
      var tagTranslationKey = this.tagOptions.find(
        (option) => option.value == this.tag
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem('Tag', tagName, 'COMMON.TAG.TITLE', tagTranslationKey)
      );
    }

    if (this.dateFrom) {
      this.selectedFilters.push(
        new ChecklistItem(
          'Date From',
          this.dateFrom.toString(),
          'COMMON.DATE_FROM'
        )
      );
    }
    if (this.dateTo) {
      this.selectedFilters.push(
        new ChecklistItem('Date To', this.dateTo.toString(), 'COMMON.DATE_TO')
      );
    }

    if (this.searchText) {
      this.selectedFilters.push(
        new ChecklistItem('Search', this.searchText, 'COMMON.SEARCH')
      );
    }

    //slt
    if (this.sltStatus && this.sltOptions.length > 0) {
      var sltStatusOptionName = this.sltOptions.find(
        (x) => x.key == this.sltStatus
      )!.value;
      this.selectedFilters.push(
        new ChecklistItem(
          this.sltStatusName,
          sltStatusOptionName,
          'COMMON.SLT_STATUS.SINGLE'
        )
      );
    }

    //use of premises
    if (this.useOfPremises) {
      var uopName = BuildingType[this.useOfPremises];
      var uopTranslationKey = this.uopOptions.find(
        (option) => option.value == this.useOfPremises
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'Use of premises',
          uopName,
          'COMMON.USE_OF_PREMISES',
          uopTranslationKey
        )
      );
    }

    if (this.filterByValue != this.filterByOptions.TOTAL) {
      if (this.filterByValue == this.filterByOptions.EXISTING) {
        this.selectedFilters.push(
          new ChecklistItem(
            'Type',
            'Existing',
            'COMMON.TYPE',
            'COMMON.FILTER_BY_OPTIONS.EXISTING'
          )
        );
      } else if (this.filterByValue == this.filterByOptions.NEW) {
        this.selectedFilters.push(
          new ChecklistItem(
            'Type',
            'New entry',
            'COMMON.TYPE',
            'COMMON.FILTER_BY_OPTIONS.NEW'
          )
        );
      } else if (this.filterByValue == this.filterByOptions.ONBOARD) {
        this.selectedFilters.push(
          new ChecklistItem(
            'Type',
            'Direct connection',
            'COMMON.TYPE',
            'COMMON.FILTER_BY_OPTIONS.ONBOARD'
          )
        );
      } else if (this.filterByValue == this.filterByOptions.REGULARIZED) {
        this.selectedFilters.push(
          new ChecklistItem(
            'Type',
            'Regularized',
            'COMMON.TYPE',
            'COMMON.FILTER_BY_OPTIONS.REGULARIZED'
          )
        );
      } else if (this.filterByValue == this.filterByOptions.RETAGGED) {
        this.selectedFilters.push(
          new ChecklistItem(
            'Type',
            'Retagged',
            'COMMON.TYPE',
            'COMMON.FILTER_BY_OPTIONS.RETAGGED'
          )
        );
      }
    }
    if (this.newEntryValue == this.newEntryOptions.AlphaMeterType) {
      this.selectedFilters.push(
        new ChecklistItem(
          'New entry type',
          'Alpha meter type',
          'CUSTOMERS.NEW_ENTRY_TYPE',
          'CUSTOMERS.NEW_ENTRY.ALPHA_METER'
        )
      );
    } else if (this.newEntryValue == this.newEntryOptions.InvalidMeterNo) {
      this.selectedFilters.push(
        new ChecklistItem(
          'New entry type',
          'Invalid meter number',
          'CUSTOMERS.NEW_ENTRY_TYPE',
          'CUSTOMERS.NEW_ENTRY.INVALID_METER_NO'
        )
      );
    } else if (this.newEntryValue == this.newEntryOptions.MeterStartsWith) {
      this.selectedFilters.push(
        new ChecklistItem(
          'New entry type',
          'Meter number starts with 24',
          'CUSTOMERS.NEW_ENTRY_TYPE',
          'CUSTOMERS.NEW_ENTRY.METER_NO_24'
        )
      );
    } else if (this.newEntryValue == this.newEntryOptions.WithAccNo) {
      this.selectedFilters.push(
        new ChecklistItem(
          'New entry type',
          'With account number',
          'CUSTOMERS.NEW_ENTRY_TYPE',
          'CUSTOMERS.NEW_ENTRY.WITH_ACC_NUMBER'
        )
      );
    } else if (this.newEntryValue == this.newEntryOptions.WithSPN) {
      this.selectedFilters.push(
        new ChecklistItem(
          'New entry type',
          'With SPN',
          'CUSTOMERS.NEW_ENTRY_TYPE',
          'CUSTOMERS.NEW_ENTRY.WITH_SPN'
        )
      );
    }

    if (this.dtId && this.dtName) {
      //dt from transformers
      this.selectedFilters.push(
        new ChecklistItem('Dt Name', this.dtName, 'COMMON.DT_NAME')
      );
    }
  }

  removeFilterStorage() {
    if (
      this.allAreas.length > 0 &&
      this.regions.length > 0 &&
      this.tariffs.length > 0
    )
      localStorage.removeItem('filterCustomer');
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  selectSearchType(searchType: any) {
    if (this.searchText && this.searchFilterApplied) {
      this.searchText = '';
      this.search();
    }
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.translation;
    this.showSelectSearchType = false;
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isServiceTypeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'serviceType'
    )
      ? false
      : true;
    this.isRegularizationUploadVisible = notVisibleFields.find(
      (el: any) => el === 'regularizationUpload'
    )
      ? false
      : true;
  }

  toggleDirectConnection() {
    this.showDirectConnection = !this.showDirectConnection;
    this.reloadTable(1);
  }
}
